import { AnswerI } from "../models/answer"
import { RoomAnswersI } from "../models/room"

interface Props {
    currentQuestion:number,
    answers:RoomAnswersI,
    answer:AnswerI,
}

export const getCountAnsweredInQuestion = ({
    currentQuestion,
    answers,
    answer
}:Props) => {

    let total = 0

    for(const idUser in answers){

      const userAnswer = answers[idUser][String(currentQuestion+1)]

      if(userAnswer?.answer===answer.id){
        total+=1
      }

    }

    return total
  }