import { useAppSelector } from "../../redux/store"
import { getUsersSortedByScore } from "../../utils/users"
import LeaderBoardCard from "./Card"
import { LeaderBoardListProps } from "./props"

const LeaderBoardList = (props: LeaderBoardListProps) => {

  const { room, usersIndex } = useAppSelector(state => state.room)

  if (!usersIndex) return

  const users = getUsersSortedByScore({
    users: room.allPlayers,
    scores: room.scores,
  })

  return (
    <div className="grid grid-cols-3 gap-1">
      {
        // select from 4th user to n user
        users.slice(3,users.length).map((e, i) => (
          // i +4 because the list is from 4th to n number of user
          <LeaderBoardCard {...props} position={i + 4}
            key={e} user={usersIndex[e]} className=" h-[2.917vw] "/>
        ))
      }
    </div>
  )
}

export default LeaderBoardList