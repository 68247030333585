import ButtonNextQuestion from "../Button/NextQuestion"
// import UIControlButtons from "../UI/ControlButtons"
import LeaderBoardContainer from "./Container"
import LeaderBoardList from "./List"
import LeaderBoardNextQuestion from "./NextQuestion"
import LeaderBoardWinners from "./Winners"
import bgLeader from '../../assets/images/bg-leaderboard.jpg';
import BottomPinCode from "../BottomPinCode"


const LeaderBoard = () => {
  return (
    <LeaderBoardContainer bgLeaderBoard={bgLeader}>
      <div className="absolute top-5 right-5">
        <LeaderBoardNextQuestion />
      </div>
      <LeaderBoardWinners className="mb-2 flex-col" />
      <LeaderBoardList />
      <div className="absolute right-5 bottom-5">
        <ButtonNextQuestion />
      </div>
      {/* <div className="absolute left-5 bottom-5">
        <UIControlButtons theme="white" />
      </div> */}
      <BottomPinCode showAudio={false} />
    </LeaderBoardContainer>
  )
}

export default LeaderBoard