import {
    createBrowserRouter,
} from "react-router-dom";
import HomePage from "./pages";
import * as Sentry from '@sentry/react'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter,
);

export const router = sentryCreateBrowserRouter([
    {
        path: "/:roomId",
        element: <HomePage />,
    },

]);