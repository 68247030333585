import { doc, onSnapshot } from "firebase/firestore";
import { FS_FIELDS } from "../../../constants/firebase";
import { db } from "../../../firebase";
import { RoomI } from "../../../models/room";
import { UserI, UsersIndexI } from "../../../models/user";
import { updateCurrentQuestionInRoom, updateCurrentSectionInRoom } from "../../../services/room";
import { AppDispatch, RootState } from "../../store";
import { goToNextQuestion, setQuestionStatus, setSection } from "../ui/reducer";
import { setOwner, setRoom, setUsersIndex } from "./reducer";

export const subscribeToRoomChanges = (roomId: string) => (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.ROOMS, roomId), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const newRoomData = docSnapshot.data() as RoomI

        if (newRoomData !== getState().room.room) {
            dispatch(setRoom({
                ...newRoomData,
                id:docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};

export const subscribeToRoomOwnerChanges = (owner: string) => (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.USERS, owner), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const data = docSnapshot.data() as UserI

        if (data !== getState().room.owner) {
            dispatch(setOwner({
                ...data,
                uid:docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};

export const subscribeToRoomIndexUsersChanges = (roomId: string) => (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const ref = doc(db, FS_FIELDS.ROOMS, roomId, FS_FIELDS.INDICES, FS_FIELDS.USERS)

    const unsubscribe = onSnapshot(ref, (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const data = docSnapshot.data() as UsersIndexI

        if (data !== getState().room.usersIndex) {
            dispatch(setUsersIndex(data))
        }

    });

    return unsubscribe;
};

export const goToNextQuestionInRoom = () => async (
    dispatch: AppDispatch,
    state: () => RootState
) => {

    const {room} = state().room
    const roomId = room.id

    try {

        await Promise.allSettled([
            updateCurrentQuestionInRoom(roomId, room?.currentQuestion + 1),
            updateCurrentSectionInRoom({
                roomId,
                currentSection: 'question',
            })
        ])

        dispatch(setSection('question'))
        dispatch(goToNextQuestion())
        dispatch(setQuestionStatus('pending'))
        return
    } catch(e){
        alert("Error")
        console.error(e)
        return 
    }
};