import { useSelector } from "react-redux"
import { useAppSelector } from "../../redux/store"
import { selectTotalQuestions } from "../../redux/modules/game/selectors"

const QuestionCurrentNumber = () => {

    const totalOfQuestions = useSelector(selectTotalQuestions)
    const {room} = useAppSelector(state=>state.room)

    return (
        <div className="bg-white p-2 text-black w-[11.458vw] text-center text-[1.458vw] rounded-md shadow-md border h-[3.490vw] flex items-center justify-center">
            <span className="uppercase text-blue-dark">question {room.currentQuestion+1}</span><span className="text-gray">&nbsp;of {totalOfQuestions}</span>  
        </div>
    )
}

export default QuestionCurrentNumber