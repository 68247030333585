import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { selectIsFirstQuestion, selectTotalOfPlayers, selectTotalOfQuestionsAnswered } from '../../redux/modules/room/selectors';
import { useAppSelector } from '../../redux/store';
import UICard from '../UI/Card';
import RacingGameTextFinish from './TextFinish';
import { DEFAULT_CAR_ANIMATION_DURATION } from './constants';
import useNextToLeaderBoard from '../../hooks/useNextToLeaderBoard';

const RacingGameOnFinish = () => {

    const isFirstQuestion = useAppSelector(selectIsFirstQuestion)
    const { handleGoToLeaderBoard } = useNextToLeaderBoard()
    const totalOfPlayers = useAppSelector(selectTotalOfPlayers)
    const totalOfQuestionsAnswered = useAppSelector(selectTotalOfQuestionsAnswered)
    const container = document.querySelector('#message')

    const delay = (() => {
        if (isFirstQuestion) return DEFAULT_CAR_ANIMATION_DURATION + 2
        return 2
    })()

    useEffect(() => {

        setTimeout(() => {
            handleGoToLeaderBoard()
        }, (delay + 3) * 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(!container) return null

    return createPortal(
        <div className='w-screen h-screen z-20'>
            <motion.div key={4}
                initial={{
                    opacity: 0,
                    y: '100vh'
                }}
                animate={{
                    opacity: 1,
                    y: '0vh'
                }}
                transition={{
                    duration: 1,
                    delay,
                }}
            >
                <RacingGameTextFinish />
            </motion.div>
            <motion.div key={24}
                initial={{
                    opacity: 0,
                    y: '100vh'
                }}
                animate={{
                    opacity: 1,
                    y: '0vh'
                }}
                transition={{
                    duration: 1,
                    delay: delay + 1,
                }}
            >
                <div className=''>
                    <UICard background='black'>
                        ANSWERED! {totalOfQuestionsAnswered} of {totalOfPlayers}
                    </UICard>
                </div>
            </motion.div>
        </div>, container,
    )
}

export default RacingGameOnFinish