import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { replaceColor } from 'lottie-colorify';
import Lottie, { AnimationItem } from 'lottie-web';
import carAnimation from '../../assets/animations/car.json';
import { DEFAULT_CAR_ANIMATION_DURATION } from '../RacingGame/constants';
import CarPlaceIndicator from './PlaceIndicator';
import { CarProps } from './props';
import useCar from './useCar';
import { useAppSelector } from '../../redux/store';

const Car: React.FC<CarProps> = ({
    initialRight,
    user,
    position,
    animationDuration = DEFAULT_CAR_ANIMATION_DURATION,
    animateToRight,
    style,
    initialTop,
    animateToTop,
}) => {
    const { transition } = useCar({ initialRight, animationDuration });
    const { section } = useAppSelector(state => state.ui);
    const container = useRef(null);
    const [animation, setAnimation] = useState<AnimationItem | null>(null);

    const loop = section === 'racing-game'

    useEffect(() => {
        if (section !== 'racing-game' && animation) {
            // TODO: Stop animation
            animation.pause();
        }
    }, [section, animation]);

    useEffect(() => {
        if (!container.current) return;

        const anim = Lottie.loadAnimation({
            autoplay: true,
            container: container.current,
            animationData: replaceColor('#017dd4', user.carColor, carAnimation),
            renderer: 'svg',
        });

        anim.setSpeed(0.3);

        // Actualiza el estado con la referencia de la animación
        setAnimation(anim);

        return () => {
            if (animation) {
                animation.destroy();
            }
        };
    }, [user]);

    return (
        <motion.div
            className={`absolute ${!loop && 'blur-sm'}`}
            initial={{
                right: initialRight || '90%',
                top: initialTop,
            }}
            animate={{
                right: animateToRight,
                transition,
                top: animateToTop,
            }}
        >
            <div className='relative w-96' style={style}>
                <CarPlaceIndicator user={user} position={position} />
                <div ref={container} />
            </div>
        </motion.div>
    );
};

export default Car;
