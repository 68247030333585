import { ReactNode } from "react"

interface Props {
  children?: ReactNode,
  className?: string
}

const UITextShadow = ({ children, className = '' }: Props) => {

  return (
    <p className={`${className} font-medium text-white`} style={{
      textShadow: '1px 1px 2px rgba(0,0,0,1), 2px 2px 2px rgba(0,0,0,1), 0px 0px 2px rgba(0,0,0,1), 0px 0px 2px rgba(0,0,0,1)',
    }}>
      {children}
    </p>
  )
}

export default UITextShadow