import { ReactNode } from "react"

interface Props {
    children: ReactNode
    onClick?: () => void,
    disabled?: boolean
}

const UIButton = ({ children, onClick, disabled }: Props) => {
    return (
        <button disabled={disabled} onClick={onClick}
            className={`border-[1px] ${!disabled ? 'cursor-pointer' : 'pointer-events-none'}  border-white p-2 rounded-lg w-[11.927vw] h-[3.490vw] text-[1.458vw]`} >
            {children}
        </button>
    )
}

export default UIButton