import { getInitials } from "../../../utils/strings"
import { USER_IMAGE_BASE_CLASSNAME } from "./constants"
import { PlayerImageProps } from "./props"

const PlayerImageNoPhoto = ({
  user, className = '',
  baseClassName = USER_IMAGE_BASE_CLASSNAME
}: PlayerImageProps) => {

  return (
    <div className={`${baseClassName} flex items-center justify-center bg-[#F6C24F] text-white border-2 border-white ${className}`}>
      {getInitials(user.name)}
    </div>
  )
}

export default PlayerImageNoPhoto