import QRCode from 'react-qr-code';
import { useAppSelector } from '../redux/store';

interface Props {
    showTitle?: boolean
    className?:string
}

const QrCode = ({
    showTitle = true,
    className = 'w-3/4 mt-5 mb-10'
}: Props) => {
    const { room } = useAppSelector((state) => state.room);
    const url = `${import.meta.env.VITE_APP_URL}/home/waiting-room/${room.id}`;

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(url).then(() => {
            alert('The link to share with your students has been copied to your clipboard:\n' + url);
        }).catch((error) => {
            alert('Error al copiar al portapapeles:\n' + error.message);
        });
    };

    return (
        <>
            {showTitle && <p className='font-OswaldRegular text-gray-light text-center'>
                QR CODE
            </p>}
            <div className='flex justify-center' onClick={handleCopyToClipboard} style={{ cursor: 'pointer' }}>
                <QRCode value={url} className={className} />
            </div>
        </>
    );
};

export default QrCode;
