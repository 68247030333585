import { UseCarProps } from "./props"

const useCar = ({
    // initialRight,
    animationDuration,
}:UseCarProps) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transition: any = (() => {

        // if (initialRight) {
        //     return {
        //         repeat: Infinity,
        //         duration: 0.8,
        //         repeatType: "reverse",
        //         stiffness: 50,
        //     }
        // }

        return {
            stiffness: 50,
            duration: animationDuration,
        }

    })()

    return {
        transition
    }

}

export default useCar