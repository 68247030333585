import { useEffect, useState } from "react"
import { setPrevCarPositions } from "../../redux/modules/ui/reducer"
import { PrevCarPositionsI } from "../../redux/modules/ui/types"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { getRandomNumber } from "../../utils/numbers"
import { getUsersSortedByScore } from "../../utils/users"
import { selectIsLastQuestion } from "../../redux/modules/room/selectors"

const useRacingGameContent = () => {

    const { room } = useAppSelector(state => state.room)
    const [carPositions, setCarPositions] = useState<PrevCarPositionsI>({})
    const dispatch = useAppDispatch()
    const usersSorted = getUsersSortedByScore({
        users: room.allPlayers,
        scores: room.scores
    })
    
    const {prevCarPositions} = useAppSelector(state=>state.ui)
    const topTenUsers = usersSorted.slice(0, 6)
    const isLastQuestion = useAppSelector(selectIsLastQuestion)

    useEffect(() => {

        const usersSize = topTenUsers.length;
        let currentPlaceInX = 50;
        const iterationDecrease = currentPlaceInX / usersSize;
        currentPlaceInX = -10

        const carPositions: PrevCarPositionsI = {}

        topTenUsers.forEach((userId,index) => {

            const animationPlaceInX = (() => {

                const score = room.scores[userId]
                currentPlaceInX += iterationDecrease;

                if(isLastQuestion) {
                    if(index===0) return 10
                }

                const place = score ? currentPlaceInX : currentPlaceInX - index

                return place + 30

            })()

            const y = (() => {
                
                let newY = getRandomNumber(7, 2);

                //Check if exist in in prev car position
                while (Object.values(carPositions).some(car => car.y === newY)) {
                    newY = getRandomNumber(7, 2);
                }
            
                return newY

            })();

            const prevY = (() => {

                const prevCarPositionsList = Object.keys(prevCarPositions)

                if(!prevCarPositionsList.length) return undefined

                const prevCar = prevCarPositions[prevCarPositionsList[index]]
                
                return prevCar?.y

            })()

            carPositions[userId] = {
                x:animationPlaceInX,
                y,
                prevY,
            }
        })

        setCarPositions(carPositions)

        return () => {
            dispatch(setPrevCarPositions(carPositions))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        carPositions,
        topTenUsers,
    }

}

export default useRacingGameContent