import { goToNextQuestionInRoom } from "../../redux/modules/room/actions"
import { useAppDispatch } from "../../redux/store"
import ButtonNext from "./Next"

const ButtonNextQuestion = () => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        return dispatch(goToNextQuestionInRoom())
    }

    return (
        <ButtonNext onClick={handleClick} />
    )
}

export default ButtonNextQuestion