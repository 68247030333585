import { UserIndexI } from "../../models/user"
import UICard from "../UI/Card"
import UITextShadow from "../UI/TextShadow"
import PlayerImage from "./Image"

interface Props {
    user?: UserIndexI
    position?: number
}

const PlayerItem = ({ user, position }: Props) => {

    return <UICard className="flex gap-5 items-center h-10 w-[90%]"
        background="blue" borderColor="black">
        {user && <>
            <UITextShadow className="text-[1.250vw]">
                {position}
            </UITextShadow>
            <PlayerImage user={user} />
            <p className="w-[70%] truncate">{user.name}</p>
        </>}
    </UICard>

}

export default PlayerItem