import { useEffect } from 'react'
import questionSound from '../assets/sounds/questionSound.mp3'
import useSound from '../hooks/useSound'
import { useAppSelector } from '../redux/store'
import QrCode from './QrCode'
import QuestionBottomContent from './Question/BottomContent'
import UIControlButtons from './UI/ControlButtons'

interface Props {
    showAudio?: boolean
    showPinCode?: boolean
}

const Audio = () => {

    const { questionStatus } = useAppSelector((state) => state.ui);
    const { audioRef, toggleMute } = useSound({ music: questionSound });

    useEffect(() => {

        if (questionStatus === 'pending' && audioRef.current && audioRef.current.paused) {
            audioRef.current.play();
        } else if (questionStatus === 'answered' && !audioRef.current.paused) {
            audioRef.current.pause();
        }
    }, [questionStatus, audioRef]);

    return (
        <UIControlButtons theme={'white'} toggleMute={toggleMute} isMuted={audioRef.current?.muted} />
    )

}

const BottomPinCode = ({
    showAudio = true,
    showPinCode = true
}: Props) => {

    return (
        <div className='absolute bottom-5 left-3 z-[100] '>
            <div className="flex flex-col items-start">
                {showPinCode && <QrCode showTitle={false} className="w-[8rem] h-[8rem]" />}
                <div className="flex">
                    {showAudio && <Audio />}
                    {showPinCode && <QuestionBottomContent />}
                </div>
            </div>
        </div>
    )
}

export default BottomPinCode
