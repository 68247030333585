import ButtonStartGame from '../Button/StartGame';
import EnterCode from '../EnterCode';
import PlayerList from '../Player/List';
import BgWaiting from '../../assets/svg/bg-waiting-room.svg';
import Trophy from '../Trophy';
import { useAppSelector } from '../../redux/store';
// import UIControlButtons from '../UI/ControlButtons';

import waitingRoomSong from '../../assets/sounds/waiting-room.mp3'
// import useSound from '../../hooks/useSound';
import { useEffect, useState } from 'react';

import soundIconOn from '../../assets/svg/sound_on.svg';
import soundIcon from '../../assets/svg/sound.svg'

const WaitingRoom = () => {
  const { trophy } = useAppSelector(state => state.trophy);
  const [audio] = useState(new Audio(waitingRoomSong));
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const loadAndPlayAudio = async () => {
      try {
        await audio.load();
        if (!isMuted) {
          audio.play();
        }
      } catch (error) {
        console.log('Error al cargar el audio', error);
      }
    };

    loadAndPlayAudio();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio, isMuted]);

  const toggleMute = () => {
    audio.muted = !isMuted;
    setIsMuted(!isMuted);


    if (!isMuted) {
      audio.play();
    }
  };


  return (
    <div className='relative flex items-center justify-center w-screen h-screen bg-blue '>
      <img src={BgWaiting} alt="" className='absolute w-screen h-screen' />
      <div className='flex justify-center w-[70%] z-10 gap-10 ml-5'>
        <EnterCode />
        <div className='flex flex-col justify-center gap-3'>
          {trophy?.userName && <Trophy trophy={trophy} />}
          <ButtonStartGame />
        </div>
        <PlayerList />
      </div>


      <div className='absolute bottom-5 left-3 '>
        <button className='cursor-pointer' onClick={toggleMute}>
          <img src={isMuted ? soundIcon : soundIconOn} alt='' className='w-10' />
        </button>
      </div>


    </div>
  );
};

export default WaitingRoom;
