import { useMemo } from "react"
import useCurrentQuestion from "../../hooks/useCurrentQuestion"
import QuestionAnswerOption from "./AnswerOption"
import './style.css'
import { DEFAULT_CHOISE_ANSWERS } from "../../constants/answer"



const Title = () => {


  const { currentQuestion } = useCurrentQuestion()
  const title = currentQuestion?.question_text || currentQuestion?.entry?.item_body || ''

  return (
    <p className="text-[2.417vw] xl:text-[2vw] 2xl:text-[1.7vw] xl:h-auto font-extrabold text-center text-blue-dark uppercase z-10"
      dangerouslySetInnerHTML={{ __html: title }} />
  )
}

const QuestionContent = () => {

  const { currentQuestion } = useCurrentQuestion()

  const questionIdWinner = (() => {

    if (currentQuestion?.entry?.interaction_data.true_choice) {
      return currentQuestion?.entry?.interaction_data.true_choice
    }

    if (currentQuestion?.entry?.scoring_data.value) return currentQuestion?.entry?.scoring_data.value

    return currentQuestion?.answers?.find(e => {
      return (e.weight || 0) > 0
    })?.id

  })()

  const answers = (() => {

    if (currentQuestion?.entry?.interaction_type_slug === "true-false") {
      return DEFAULT_CHOISE_ANSWERS
    }

    return currentQuestion?.answers || currentQuestion?.entry?.interaction_data.choices
  })()

  return (

    <div className=" mx-auto text-gray-500 w-1/2 ">
      <div className=" px-20 flex flex-col items-center m-auto">
        {useMemo(() => <Title />, [])}
        <div className="flex flex-col gap-3 w-full">
          {answers?.map((e, i) => (
            <QuestionAnswerOption answer={e} key={i}
              isWinner={e.id === questionIdWinner} />
          ))}
        </div>
      </div>
    </div>
  )

}

export default QuestionContent