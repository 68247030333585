import { Timestamp, doc, onSnapshot } from "firebase/firestore"
import { FS_FIELDS } from "../../../constants/firebase"
import { db } from "../../../firebase"
import { TrophyI, WinnerHistoryI } from "../../../models/trophy"
import { updateRoom } from "../../../services/room"
import { saveWinnerHistory, updateTrophy } from "../../../services/trophy"
import { getWinnerUserInRoom } from "../../../utils/users"
import { AppDispatch, RootState } from "../../store"
import { setTrophy } from "./reducer"

export const subscribeToTrophyChanges = (tropyId: string) => (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.TROPHIES, tropyId), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const data = docSnapshot.data() as TrophyI

        if (data !== getState().trophy.trophy) {
            dispatch(setTrophy({
                ...data,
                uid: docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};

export const saveWinnerHistoryInTrophy = () => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const { room, usersIndex, owner } = getState().room
    const { game } = getState().game
    const { trophy: trophyId } = owner

    const winnerUser = getWinnerUserInRoom({
        users: room.allPlayers,
        scores: room.scores,
        usersIndex,
    })

    const winnerHistory: WinnerHistoryI = {
        room: room.id,
        user: winnerUser.uid,
        isAnonymus: winnerUser.isAnonymous,
        createdDate: Timestamp.now(),
        score: room.scores[winnerUser.uid] || 0,
        game: String(game?.id),
        courseName:room.courseName || ''
    }

    const [snapWinnerHistory] = await Promise.all([
        saveWinnerHistory({
            trophyId,
            data: winnerHistory,
        }),
        updateRoom(room.id, { endDate: new Date() })
    ])

    if (winnerUser.isAnonymous) return

    return await dispatch(saveLastWinnerInTrophy(
        snapWinnerHistory.id,
    ))

}

export const saveLastWinnerInTrophy = (winnerHistory: string) => async (
    _: AppDispatch,
    getState: () => RootState
) => {

    const { room, usersIndex, owner } = getState().room
    const { trophy: trophyId } = owner

    const winnerUser = getWinnerUserInRoom({
        users: room.allPlayers,
        scores: room.scores,
        usersIndex,
    })

    return await updateTrophy({
        trophyId,
        data: {
            user: winnerUser.uid,
            userName: winnerUser.name,
            courseName:room.courseName || '',
            winnerHistory,
        }
    })

}