import bgImg from '../../assets/svg/bg-frame-white.svg'
import { useAppSelector } from "../../redux/store"
import QuestionContent from "./Content"
import QuestionTopContent from "./TopContent"

import BottomPinCode from "../BottomPinCode"

const Question = () => {
  const { questionStatus } = useAppSelector((state) => state.ui);

  return (
    <div className={`w-screen h-screen flex flex-col relative ${questionStatus === 'pending' ? 'bg-yellow' : 'bg-purple'}`}>
      <img src={bgImg} alt='' className='absolute inset-0 w-full h-full' />

      <div className='sticky top-0 z-10'>
        <QuestionTopContent />
      </div>

      <div className='overflow-y-auto flex-grow'>
        <QuestionContent />
      </div>

      <BottomPinCode showPinCode={false} />
    </div>
  );
};

export default Question;
