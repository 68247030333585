import { UserIndexI } from '../../models/user'
import PlayerImage from '../Player/Image'
import UITextShadow from '../UI/TextShadow'

interface Props {
    position:number
    user:UserIndexI
}

const CarPlaceIndicator = ({position,user}:Props) => {
    return (
        <div className='absolute left-40 right-0  ml-auto mr-auto w-[fit-content] -top-5  z-10 h-10 rounded-r-full fit max-w-[144px] pl-2'
        style={{
            background:user.carColor
        }}>
            <div className='flex gap-2 items-center justify-center ml-1 mt-1 bread'>
                <UITextShadow className='truncate'>
                    {position} {user.name}
                </UITextShadow>
                <PlayerImage user={user} />
            </div>
        </div>
    )
}

export default CarPlaceIndicator