
import soundIconOn from '../../../assets/svg/sound_on.svg';
import soundIcon from '../../../assets/svg/sound.svg'
import soundIconBlack from '../../../assets/svg/sound-black.svg';
// import fullScreenIcon from '../../../assets/svg/fullscreen.svg';
import fullScreenIconBlack from '../../../assets/svg/fullscreen-black.svg';
// import settingsIcon from '../../../assets/svg/settings.svg';
import settingsIconBlack from '../../../assets/svg/settings-black.svg';


interface UIControlButtonsProps {
    theme: 'black' | 'white';
    toggleMute: () => void;
    isMuted: boolean;
}

const UIControlButtons: React.FC<UIControlButtonsProps> = ({ theme, toggleMute, isMuted }) => {

    return (
        <div className='flex gap-2 '>
            {theme === 'white' ? (
                <>

                    <button className='cursor-pointer' onClick={toggleMute}>
                        <img src={isMuted ? soundIcon : soundIconOn} alt='' className='w-10' />
                    </button>


                    {/* <img src={fullScreenIcon} alt='' />
                    <img src={settingsIcon} alt='' /> */}
                </>
            ) : (
                <>
                    <img src={soundIconBlack} alt='' />
                    <img src={fullScreenIconBlack} alt='' />
                    <img src={settingsIconBlack} alt='' />
                </>
            )}
        </div>
    );
};

export default UIControlButtons;
