import UIButton from "../UI/Button"
import LeaderBoardContainer from "./Container"
import LeaderBoardList from "./List"
import LeaderBoardWinners from "./Winners"

import winnerSound from '../../assets/sounds/winnerSound.mp3'
import useSound from "../../hooks/useSound"
import UIControlButtons from "../UI/ControlButtons"

const LeaderBoardFinal = () => {

  const { audioRef, toggleMute } = useSound({ music: winnerSound });



  return (
    <LeaderBoardContainer>
      <div className="absolute top-5 right-5">
        <UIButton onClick={() => {
          const url = import.meta.env.VITE_ADMIN_APP
          window.open(`${url}/dashboard`, '_self');
        }}>
          FINISHED
        </UIButton>
      </div>
      <LeaderBoardWinners className="mb-2 gap-5 lg:gap-20 2xl:gap-36 flex-col lg:flex-row" flexCol="flex-col" typeCardWinner="detail" bgSecondPlace="bg-2nd-place-gradient" bgThirdPlace="bg-3rd-place-gradient" />
      <div className="md:ml-3">
        <LeaderBoardList />
      </div>
      <div className='absolute bottom-0 left-3'>
        <UIControlButtons theme={'white'} toggleMute={toggleMute} isMuted={audioRef.current?.muted} />
      </div>
    </LeaderBoardContainer>
  )
}

export default LeaderBoardFinal