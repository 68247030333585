// import { useAppSelector } from "../../redux/store"
// import ButtonEndGame from "../Button/EndGame"
// import ButtonNextToRacingGame from "../Button/NextToRacingGame"
import PinCode from "../PinCode"
// import UIControlButtons from "../UI/ControlButtons"

const QuestionBottomContent = () => {

    // const { questionStatus } = useAppSelector(state => state.ui)

    return (
        <div className="p-1 z-10">
            <div className="flex justify-between">
                <div className="flex items-center gap-6">
                    <div className="text-white font-bold flex items-center gap-10">
                            {/* <UIControlButtons theme="black"/> */}
                        <div className="flex items-center gap-2">
                            <b className="text-xl mr-16">GAME PIN CODE</b>
                            <PinCode />
                        </div>
                    </div>
                </div>
                {/* <div className="flex items-center gap-6">
                    <ButtonEndGame />
                </div> */}
            </div>
        </div>
    )
}

export default QuestionBottomContent