import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import background from "../../assets/animations/background-racing.json";
import { selectIsLastQuestion } from "../../redux/modules/room/selectors";
import { useAppSelector } from "../../redux/store";
import LeaderBoard from "../LeaderBoard";
import LeaderBoardFinal from "../LeaderBoard/Final";
import Winner from "../Winner";
import flagRace from "../../assets/images/flag-finish.png";
interface Props {
    children?: ReactNode;
    className?: string;
}

const Line = ({ className = "" }: Props) => {
    const isLastQuestion = useAppSelector(selectIsLastQuestion);
    const [isVisible, setIsVisible] = useState(false);
    // const {room} = useAppSelector(state=>state.room)
    // const showLine = room.currentSection==='leader-board-final' && isLastQuestion

    useEffect(() => {
        if (!isLastQuestion) return;

        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    }, [isLastQuestion]);

    if (!isLastQuestion || !isVisible) return null;

    return (
        <motion.div
            initial={{
                right: 0,
                display: "none",
            }}
            animate={{
                right: "25%",
                display: "block",
            }}
            transition={{
                duration: 3,
            }}
            className={`${className} absolute h-screen w-12 top-20 -z-10 `}
            style={{
                backgroundImage: `url(${flagRace})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
            }}
        ></motion.div>
    );
};

const RenderLeaderBoard = () => {
    const { section } = useAppSelector((state) => state.ui);

    if (section === "racing-game") return null;

    const Render = () => {
        if (section === "leader-board") return <LeaderBoard />;
        if (section === "leader-board-final") return <LeaderBoardFinal />;
        if (section === "finish-game") return <Winner />;
        return null;
    };

    return (
        <motion.div
            key={1}
            className="absolute z-30"
            initial={{
                top: 1000,
            }}
            animate={{
                top: 0,
            }}
            transition={{
                duration: 2,
            }}
        >
            <Render />
        </motion.div>
    );
};

const RacingGameContainer = ({ children }: Props) => {
    const { section } = useAppSelector((state) => state.ui);
    const loop = section === "racing-game";

    return (
        <div className="fixed top-0 left-0 w-full h-full z-10">
            <Lottie
                animationData={background}
                loop={loop}
                autoPlay={true}
                className={`scale-150 h-full 2xl:scale-125 2xl:h-[100%] bg-black ${!loop && "blur-md brightness-50 duration-700"
                    }`}
            />
            <div className="absolute z-20 top-0 bottom-0 flex items-center pt-40">
                {useMemo(() => children, [children])}
                <Line className={`${!loop && "blur-md"}`} />
            </div>
            <RenderLeaderBoard />
        </div>
    );
};

export default RacingGameContainer;
