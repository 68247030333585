import { doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"
import { FS_FIELDS } from "../constants/firebase"
import { SectionI } from "../redux/modules/ui/types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateRoom = async (roomId: string, data: any) => {

    await updateDoc(
        doc(db, FS_FIELDS.ROOMS, roomId),
        data
    )

    return data

}

export const updateCurrentQuestionInRoom = async (
    roomId: string, numberOfQuestion: number
) => {

    return await updateRoom(roomId, {
        currentQuestion: numberOfQuestion,
        questionState: new Date(),
    })

}

interface UpdateCurrentSectionInRoomProps {
    roomId: string,
    currentSection:SectionI
}

export const updateCurrentSectionInRoom = async (
    { roomId, currentSection }: UpdateCurrentSectionInRoomProps
) => {

    return await updateRoom(roomId,{currentSection})

}

export const startGameInRoom = async (
    roomId: string
) => {

    return await updateRoom(roomId, {
        currentQuestion: 0,
        currentSection:'question',
        active: true,
        started: true,
        questionState: new Date(),
    })

}