import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TrophyI } from '../../../models/trophy';
import { TrophyState } from "./types";

const initialState: TrophyState = {}

const RoomSlice = createSlice({
    name: 'trophy',
    initialState,
    reducers: {
        setTrophy: (state, action: PayloadAction<TrophyI>) => {
            state.trophy = action.payload;
        },
    },
})

export const { setTrophy } = RoomSlice.actions;

export default RoomSlice.reducer