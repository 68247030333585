import checkSvg from '../../assets/svg/check.svg'
import { AnswerI } from "../../models/answer"
import { useAppSelector } from "../../redux/store"
import { getCountAnsweredInQuestion } from "../../utils/questions"
import UICard from "../UI/Card"
import { UICardBackgound } from "../UI/Card/props"
import UITextShadow from "../UI/TextShadow"

interface Props {
  answer: AnswerI
  isWinner?: boolean,
}

const ImageCheck = () => (
  <img src={checkSvg} className='h-[2.083vw]' />
)

const Content = ({ answer, isWinner }: Props) => {

  const { questionStatus } = useAppSelector(state => state.ui)
  const { room } = useAppSelector(state => state.room)
  const isAnswered = questionStatus === 'answered'

  const totalAnswered = getCountAnsweredInQuestion({
    answer,
    answers: room?.answers || {},
    currentQuestion: room.currentQuestion,
  })
  const text = answer.text || answer.item_body || ""

  return (
    <div className={`flex ${isAnswered ? 'justify-around' : 'justify-center'} text-[1.563vw] items-center`}>
      {(isAnswered && isWinner) ? <ImageCheck /> : <div></div>}
      <div className='flex justify-center w-4/5'>
        <div className='text-blue-dark' dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      {isAnswered && <UITextShadow className='text-[2.083vw]'> {totalAnswered} </UITextShadow>}
    </div>
  )
}

const QuestionAnswerOption = (props: Props) => {

  const { questionStatus } = useAppSelector(state => state.ui)
  const getBackground = (): UICardBackgound => {

    if (questionStatus === 'answered' && props.isWinner) {
      return 'white'
    }

    return 'transparent'
  }

  return (
    <UICard className="grid items-center min-h-[5.417vw] " background={getBackground()} >
      <Content {...props} />
    </UICard>
  )
}

export default QuestionAnswerOption