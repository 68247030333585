import { RoomScoresI } from "../models/room"
import { UsersIndexI } from "../models/user"

interface GetUserScoreProps {
    scores?: RoomScoresI
    userId: string
}

export const getUserScore = ({
    scores = {}, userId
}: GetUserScoreProps) => {
    return scores[userId] || 0
}

interface GetUsersSortedByScoreProps {
    users?: string[],
    scores: RoomScoresI,
}

export const getUsersSortedByScore = ({
    users, scores
}: GetUsersSortedByScoreProps) => {

    return [...users || []]?.sort((a, b) => {

        return getUserScore({
            scores,
            userId: b,
        }) - getUserScore({
            scores,
            userId: a,
        })

    })

}

export const getWinnerUserIdInRoom = (props: GetUsersSortedByScoreProps) => {

    const usersSorted = getUsersSortedByScore(props)

    return usersSorted[0]

}

interface GetWinnerUserInRoomProps extends GetUsersSortedByScoreProps {
    usersIndex:UsersIndexI
}

export const getWinnerUserInRoom = (props: GetWinnerUserInRoomProps) => {

    const winnerUserId = getWinnerUserIdInRoom(props)
    const winnerUser = props.usersIndex[winnerUserId]

    return winnerUser

}