import { useAppSelector } from '../../redux/store'
import PlayerItem from './Item'

const PlayerList = () => {

    const { room, usersIndex } = useAppSelector(state => state.room)
    const playersLenght = room?.allPlayers?.length || 0
    const iterations = Math.max(10 - playersLenght, 1)

    const UsersList = () => {

        if(!usersIndex) return null

        return (
            room?.allPlayers?.map((k, i) => (
                <PlayerItem user={usersIndex[k]}
                    key={k} position={i + 1} />
            ))
        )
    }

    return (
        <div className='flex flex-col z-10 w-1/3 justify-center '>

            <div className=' flex justify-center mb-5 '>
                <div className='flex gap-2 items-center text-center w-72 '>
                    <i className='rounded-full bg-gradient-to-r from-[#757B96] from-100% to-[#757B96] to-0% w-10 h-10 border border-black text-center grid items-center pr-1'>
                        {playersLenght}
                    </i>
                    <h3 className='text-2xl  '>WAITING FOR PLAYERS...</h3>
                </div>
            </div>


            <div className='overflow-scroll' style={{ maxHeight: '550px', overflowY: 'auto', overflowX: 'hidden' }}>
                {/* Users in list */}
                <UsersList />

                {/* Users pending to enter */}
                {Array.from({ length: iterations }).map((_, index) => (
                    <PlayerItem key={index} />
                ))}
            </div>


        </div>
    )
}

export default PlayerList