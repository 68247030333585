import { selectIsFirstQuestion } from '../../redux/modules/room/selectors';
import { useAppSelector } from '../../redux/store';
import Car from '../Car';
import { DEFAULT_CAR_ANIMATION_DURATION } from './constants';
import useRacingGameContent from './useContent';

const RacingGameContent = () => {

    const { usersIndex } = useAppSelector(state => state.room)
    const {carPositions, topTenUsers} = useRacingGameContent()
    const isFirstQuestion = useAppSelector(selectIsFirstQuestion)
    const {prevCarPositions} = useAppSelector(state=>state.ui)

    if(!Object.keys(carPositions).length) return null

    return (
        <div className='w-screen'>
            {topTenUsers.map((userId, index) => {

                const user = usersIndex[userId]
                const {x,y, prevY} = carPositions[userId]

                const initialRight = (() => {

                    if(isFirstQuestion) return undefined

                    const prevX = prevCarPositions[userId]?.x

                    if(prevX) {
                        if(index!==0) return `${prevX}%`
                        return `${prevX+20}%`
                    }
                    
                    return undefined

                })()

                return (
                    <Car key={index}
                        animateToRight={`${x}%`}
                        initialRight={initialRight}
                        position={index + 1}
                        initialTop={`${y}%`}
                        animateToTop={prevY ? `${prevY}%` : undefined}
                        user={user}
                        animationDuration={DEFAULT_CAR_ANIMATION_DURATION}
                    />
                )
            })}
        </div>
    );
};

export default RacingGameContent;
