import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PrevCarPositionsI, QuestionStatusI, SectionI, UIState } from './types';

const initialState: UIState = {
    section: 'loading',
    currentQuestionIndex: 0,
    questionStatus:'pending',
    prevCarPositions:{},
}

const GameSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setPrevCarPositions: (state, { payload }: PayloadAction<PrevCarPositionsI>) => {
            state.prevCarPositions = payload;
        },
        setSection: (state, { payload }: PayloadAction<SectionI>) => {
            state.section = payload;
        },
        setCurrentQuestionIndex: (state, { payload }: PayloadAction<number>) => {
            state.currentQuestionIndex = payload
        },
        goToNextQuestion: (state) => {
            state.currentQuestionIndex = state.currentQuestionIndex+1
        },
        setQuestionStatus: (state, {payload}: PayloadAction<QuestionStatusI>) => {
            state.questionStatus = payload
        }
    },
})

export const {
    setSection, setCurrentQuestionIndex,
    goToNextQuestion, setQuestionStatus,
    setPrevCarPositions,
} = GameSlice.actions;

export default GameSlice.reducer