import { useAppSelector } from "../redux/store"

const useCurrentQuestion = () => {

    const { game } = useAppSelector(state => state.game)
    const {room} = useAppSelector(state=>state.room)

    const currentQuestion = game?.questions[room.currentQuestion]

    return {
        currentQuestion
    }

}

export default useCurrentQuestion