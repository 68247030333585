
export const getInitials = (name: string) => {
  
  const words = name.split(" ").slice(0, 2);

  return words.map(word => {
    const firstChar = Array.from(word)[0];
    return firstChar ? firstChar.toUpperCase() : '';
  }).join("");

}
