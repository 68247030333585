import { useParams } from 'react-router-dom'
import bgImg from '../../assets/svg/bg-countdown.svg'
import useCountdown from "../../hooks/useCountdown"
import { setSection } from "../../redux/modules/ui/reducer"
import { useAppDispatch } from "../../redux/store"
import { startGameInRoom } from "../../services/room"
import './style.css'

const Countdown = () => {

    const { roomId } = useParams()

    const dispatch = useAppDispatch()
    const onComplete = async () => {
        await startGameInRoom(String(roomId))
        dispatch(setSection('question'))
    }

    const { count } = useCountdown({
        onComplete,
        defaultCount: 5,
    })

    return (
        <div className="flex items-center justify-center w-screen h-screen bg-blue absolute">
            <img src={bgImg} alt="" className="absolute inset-0 w-full h-full object-cover" />
            <div className="flex flex-col items-center justify-center text-center">
                <h4 className="text-xl h-20">GAME STARTS IN</h4>
                <h1 className="countdown text-9xl font-bold"> {count}</h1>
            </div>
        </div>
    )
}

export default Countdown