import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { GameI } from "../../../models/game";
import { AppDispatch, RootState } from "../../store";
import { setGame } from "./reducer";
import { FS_FIELDS } from "../../../constants/firebase";

export const subscribeToGameChanges = (gameId: string) => (dispatch: AppDispatch, getState: () => RootState) => {

    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.GAMES, gameId), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const newData = docSnapshot.data() as GameI

        if (newData !== getState().game.game) {
            dispatch(setGame({
                ...newData,
                id: docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};