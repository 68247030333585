import { useAppSelector } from "../../redux/store"
import { getUserScore } from "../../utils/users"
import PlayerImage from "../Player/Image"
import UICard from "../UI/Card"
import UITextShadow from "../UI/TextShadow"
import { LeaderBoardCardProps } from "./props"

const LeaderBoardCard = ({
    background = 'white',
    borderColor,
    className = '',
    user,
    position,
    text
}: LeaderBoardCardProps) => {

    const { room } = useAppSelector(state => state.room)
    const currentScore = getUserScore({
        scores: room.scores,
        userId: user.uid,
    })

    return (
        <UICard background={background} borderColor={borderColor}
            className={`flex justify-between items-center gap-4 
            ${className} ${background === 'white' ? 'text-black' : 'text-white'} `}>
            <div className="flex items-center gap-3">
                {currentScore > 0 && <UITextShadow className="text-[1.250vw]">{position}{text}</UITextShadow>}
                <PlayerImage user={user} baseClassName="w-5 h-5 xl:w-8 xl:h-8 rounded-full" />
                <span className="text-[1.250vw]">{user.name}</span>
            </div>
            <p>{currentScore?.toFixed(0)}</p>
        </UICard>
    )
}

export default LeaderBoardCard