import svgFlag from '../../assets/svg/flag.svg'
import svgGamify from '../../assets/svg/logo-gamify.svg'

const Loading = () => {
  return (
    <div className='bg-blue w-screen h-screen flex items-center justify-between'>

      <div className='ml-5'>
        <img src={svgGamify} alt="" className='' />
        <h2 className='text-center text-[3.125vw]'>LOADING....</h2>
      </div>
      <img src={svgFlag} className='w-[80%]' />
    </div>
  )
}

export default Loading