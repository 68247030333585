import { selectTotalOfPlayers, selectTotalOfQuestionsAnswered } from "../../redux/modules/room/selectors"
import { useAppSelector } from "../../redux/store"

const QuestionTotalAnswered = () => {

  const totalOfPlayers = useAppSelector(selectTotalOfPlayers)
  const totalOfQuestionsAnswered = useAppSelector(selectTotalOfQuestionsAnswered)

  return (
    <div className="text-gray-700 font-bold flex gap-1 items-center ">
      <p className="text-6xl">{totalOfQuestionsAnswered}</p>
      <p className="text-2xl">of {totalOfPlayers} students <br /> answered</p>
    </div>
  )
}

export default QuestionTotalAnswered