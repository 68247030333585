import { useEffect, useState } from "react"

interface Props {
    defaultCount?:number,
    onComplete?: () => void,
}

const useCountdown = ({
    defaultCount = 5,
    onComplete
}:Props) => {

    const [count, setCount] = useState(defaultCount)

    const resetCount = () => setCount(defaultCount)

    useEffect(() => {

        const interval = setInterval(() => {
            setCount((prevCount) => prevCount - 1)
        }, 1000)

        if (count <= 0) {
            clearInterval(interval)
            onComplete && onComplete()
        }

        return () => clearInterval(interval)

    }, [count])

    return {
        count,
        resetCount
    }

}

export default useCountdown