import { useEffect } from 'react'
import championShipSvg from '../../assets/svg/trophy.svg'
import useEndGame from '../../hooks/useEndGame'
import { useAppSelector } from "../../redux/store"
import { getWinnerUserInRoom } from "../../utils/users"
import LeaderBoardContainer from "../LeaderBoard/Container"
import UITextShadow from "../UI/TextShadow"

import finishLineSound from '../../assets/sounds/soundFinishLine.mp3'
import useSound from '../../hooks/useSound'

const Winner = () => {

  const music = finishLineSound
  useSound({music})

  const {
    room, usersIndex
  } = useAppSelector(state => state.room)
  // const { trophy } = useAppSelector(state => state.trophy)

  const userWinner = getWinnerUserInRoom({
    scores: room.scores,
    usersIndex,
    users: room.allPlayers,
  })
  const {handleEndGame} = useEndGame()
  
  useEffect(() => {

    setTimeout(() => {
      handleEndGame()
    }, 4000);

  }, [])

  const text = `Congrats to our ${userWinner.isAnonymous ? 'winner' : 'new champion'}`

  return (
    <LeaderBoardContainer childrenPadding="" showBgStar={false} >
      <div className=" w-screen h-screen flex flex-col items-center justify-center">

        <div className="flex flex-col gap-20 text-center">
          <UITextShadow className=" text-[6.667vw] uppercase">
            {text}
          </UITextShadow>
          <UITextShadow className="text-[10.417vw] uppercase">
            {userWinner?.name}
          </UITextShadow>

        </div>


        {!userWinner.isAnonymous && <img src={championShipSvg}
          className='h-20 md:h-36 lg:h-52 mt-5  ' />}

        {/* <UITextShadow className="pt-5 text-2xl">
          For winning the cup this time
        </UITextShadow>
        {(userWinner.isAnonymous && trophy?.userName) && <>
          prev winner: {trophy.userName}
        </>}
        <UITextShadow className="pt-8 text-2xl">
          You can be the next winner
        </UITextShadow>
        <UITextShadow className="pt-5 text-4xl">
          KEEP IT UP!
        </UITextShadow> */}
      </div>
    </LeaderBoardContainer>
  )
}

export default Winner