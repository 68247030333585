import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameI } from '../../../models/game';
import { GameState } from "./types";

const initialState: GameState = {}

const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setGame: (state, action: PayloadAction<GameI>) => {
            state.game = action.payload;
        },
    },
})

export const { setGame } = GameSlice.actions;

export default GameSlice.reducer