import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RoomState } from "./types";
import { RoomI } from '../../../models/room';
import { UserI, UsersIndexI } from '../../../models/user';

const initialState: RoomState = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    room:{} as any,
    usersIndex:{},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    owner: {} as any
}

const RoomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        setRoom: (state, action: PayloadAction<RoomI>) => {
            state.room = action.payload;
        },
        setUsersIndex: (state, action: PayloadAction<UsersIndexI>) => {
            state.usersIndex = action.payload;
        },
        setOwner: (state, action: PayloadAction<UserI>) => {
            state.owner = action.payload;
        },
    },
})

export const { setRoom, setUsersIndex, setOwner } = RoomSlice.actions;

export default RoomSlice.reducer