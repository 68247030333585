import { useAppSelector } from "../../redux/store"

import QuestionCurrentNumber from "./CurrentNumber"
import QuestionTimeRemaining from "./TimeRemaining"
import QuestionTotalAnswered from "./TotalAnswered"

const QuestionTopContent = () => {

    const { questionStatus } = useAppSelector(state=>state.ui)

    return (
        <div className="flex px-2 pt-2 justify-between z-10  ">
            <div className="w-7/12  flex justify-between">
                <QuestionCurrentNumber />
                { questionStatus==='pending' && <QuestionTotalAnswered /> }
            </div>
            <div className="w-[48%]  flex justify-end">
                <QuestionTimeRemaining /> 
            </div>
        </div>
    )
}

export default QuestionTopContent