import { setSection } from "../../redux/modules/ui/reducer"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import buttonActive from '../../assets/svg/button-active.svg'
import { startGameInRoom } from "../../services/room"

const ButtonStartGame = () => {

  const dispatch = useAppDispatch()
  const { room } = useAppSelector(state => state.room)

  return (
    <img src={buttonActive} alt="" className="cursor-pointer z-10" onClick={async () => {
      dispatch(setSection('loading'))
      await startGameInRoom(room.id)
      dispatch(setSection('countdown'))
    }} />
  )
}

export default ButtonStartGame

