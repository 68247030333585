import { NewQuestioChoiseI } from "../models/answer";

export const DEFAULT_CHOISE_ANSWERS: NewQuestioChoiseI[] = [
    {
        id: "True",
        item_body: "True"
    },
    {
        id: "False",
        item_body: "False"
    },
]