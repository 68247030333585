import championShipSvg from '../../assets/svg/trophy.svg'
import { UserIndexI } from '../../models/user'
// import { useAppSelector } from '../../redux/store'
// import { getAbstractOfAnswersByUser } from '../../utils/answers'
import PlayerImage from '../Player/Image'
import UITextShadow from '../UI/TextShadow'
// import checkSvg from '../../assets/svg/check.svg'
// import xSvg from '../../assets/svg/x.svg'

interface Props {
    user: UserIndexI
}

// const Item = ({ text, src }: { text: string, src:string }) => (
//     <div className='flex items-center gap-2'>
//         <img src={src} width={20} height={20} />
//         <p>{text}</p>
//     </div>
// )

// const AbstractAnswers = ({ user }: Props) => {

//     const { room } = useAppSelector(state => state.room)
//     const {
//         correctAnswers, incorrectAnswers
//     } = getAbstractOfAnswersByUser(room.answers[user.uid])

//     return <div className='flex justify-center gap-2 text-black font-black'>
//         <Item text={correctAnswers.toString()} src={checkSvg} />
//         <Item text={incorrectAnswers.toString()} src={xSvg} />
//     </div>

// }

const LeaderBoardCardWinner = ({ user }: Props) => {
    return (
        <div className='relative'>
            <PlayerImage user={user} baseClassName="rounded-full w-16 h-16 lg:h-32 lg:w-32 absolute -left-6 lg:-left-10 top-1/2 transform -translate-y-1/2" className='text-xs xl:text-4xl'/>

            <div className='flex flex-col w-[32.344vw] items-center justify-center mx-4 px-4 py-2 lg:h-36 xl:h-52 bg-leader-gradient bg-cover rounded-2xl border border-b-[#bc742b] border-t-[#e5d0a9] border-l-[#bc742b]'>
                <UITextShadow className='py-1 text-[3.906vw] max-w-[60%] xl:max-w-[70%] truncate'>
                    {user.name}
                </UITextShadow>
                {/* <AbstractAnswers user={user} /> */}
            </div>
            <img src={championShipSvg}
                className='h-20 lg:h-36 xl:h-52 absolute -right-4 lg:-right-10 xl:-right-16 top-1/2 transform -translate-y-1/2' />
        </div>
    )
}
export default LeaderBoardCardWinner