import { useRef, useEffect } from 'react';
import { useAppSelector } from '../../redux/store';
import RacingGameContainer from './Container';
import RacingGameContent from './Content';
import RacingGameOnFinish from './OnFinish';

import carSound from '../../assets/sounds/carRaceSound.mp3'
import finishLineSound from '../../assets/sounds/soundFinishLine.mp3'

const RenderRacingGameOnFinish = () => {

  const {section} = useAppSelector(state=>state.ui)
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const loadAndPlayAudio = async () => {
      try {
        if (section === 'racing-game') {
          // Agrega la URL del audio específico para RacingGame
          audioRef.current.src = finishLineSound; // Ejemplo con carSound
          audioRef.current.loop = true;
          await audioRef.current.load();
          audioRef.current.play();
        } else {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      } catch (error) {
        console.error('Error al cargar el audio:', error);
      }
    };

    loadAndPlayAudio();

    // Limpiar el audio al desmontar el componente
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, [section]);


  if(section!=='racing-game') return null

  return (
    <RacingGameOnFinish />
  )

}

const RacingGame = () => {

  const { section } = useAppSelector((state) => state.ui);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const loadAndPlayAudio = async () => {
      try {
        if (section === 'racing-game') {
          // Agrega la URL del audio específico para RacingGame
          audioRef.current.src = carSound; // Ejemplo con carSound
          audioRef.current.loop = true;
          await audioRef.current.load();
          audioRef.current.play();
        } else {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      } catch (error) {
        console.error('Error al cargar el audio:', error);
      }
    };

    loadAndPlayAudio();

    // Limpiar el audio al desmontar el componente
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, [section]);

  return (
    <RacingGameContainer>
      <div className="pr-20 ">
        <RacingGameContent />
      </div>
      <RenderRacingGameOnFinish />
    </RacingGameContainer>
  );

};

export default RacingGame;
