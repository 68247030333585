import trophySvg from '../../assets/svg/trophy.svg'
import UITextShadow from "../UI/TextShadow"

interface Props {
    trophy: {userName:string,name:string}
}

const Trophy = ({ trophy }: Props) => {
    return (
        <div className="flex flex-col items-center gap-1">
            <img src={trophySvg} className="max-w-[50%] " />
            <p className="bg-white rounded-md text-black font-black px-2 py-1 mt-1 font-poppins text-[1.563vw]">
                {trophy.userName}
            </p>
            <UITextShadow className="capitalize text-[1.250vw]">
            {`(${trophy.name})`}
            </UITextShadow>
        </div>
    )
}

export default Trophy