import { setSection } from '../redux/modules/ui/reducer'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { updateCurrentSectionInRoom } from '../services/room'

const useEndGame = () => {

    const dispatch = useAppDispatch()
    const { room } = useAppSelector(state => state.room)

    const handleEndGame = async () => {

        try {

            // await dispatch(saveWinnerHistoryInTrophy())
            await updateCurrentSectionInRoom({
                roomId:room.id,
                currentSection:'leader-board-final',
            })
            dispatch(setSection('leader-board-final'))

        } catch (e) {
            alert("error end game")
            console.error(e)
        }

    }

    return {
        handleEndGame
    }
}

export default useEndGame