import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { RoomAnswerI, RoomI } from "../../../models/room";

const selectRoom = (state: RootState) => state.room.room

export const selectIsFirstQuestion = createSelector(
    selectRoom,
    (room) => room?.currentQuestion === 0
);

export const selectIsLastQuestion = createSelector(
    selectRoom,
    (state: RootState) => state.game.game,
    (room,game) => {
        return (room.currentQuestion +1) === (game?.questions.length || 0)
    }
);

const calculateTotalQuestions = (room: RoomI, predicate: (answer:RoomAnswerI) => boolean) => {
    const { answers } = room;
    const questionId = room.currentQuestion + 1;
    let cont = 0;

    Object.keys(answers).forEach((userId) => {
        const answer = answers[userId][questionId];

        if (answer && predicate(answer)) {
            cont++;
        }
    });

    return cont;
};

export const selectTotalOfQuestionsAnswered = createSelector(
    selectRoom,
    (room) => calculateTotalQuestions(room, () => true)
);

export const selectTotalOfCorrectQuestionsAnswered = createSelector(
    selectRoom,
    (room) => calculateTotalQuestions(room, (answer) => answer.correct)
);

export const selectTotalOfPlayers = createSelector(
    selectRoom,
    (room) => room.allPlayers?.length || 0
)