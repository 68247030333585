import { selectTotalOfCorrectQuestionsAnswered } from "../../redux/modules/room/selectors"
import { useAppSelector } from "../../redux/store"
import UITextShadow from "../UI/TextShadow"
import { DEFAULT_RANGE_MESSAGES } from "./constants"

const RacingGameTextFinish = () => {

    const { usersIndex } = useAppSelector(state => state.room)
    const { game } = useAppSelector(state => state.game)
    const totalOfCorrectQuestionsAnswered = useAppSelector(selectTotalOfCorrectQuestionsAnswered)

    const text = (() => {

        const totalOfUsers = Object.keys(usersIndex).length
        const percentageOfAnswered = (totalOfCorrectQuestionsAnswered / totalOfUsers) * 100
        const rangeMessages = game?.rangeMessages || DEFAULT_RANGE_MESSAGES

        const option = rangeMessages.find(e => {
            return percentageOfAnswered <= e.max && percentageOfAnswered >= e.min
        })

        if (option) return option.message

        return ''

    })()

    return (
        <UITextShadow className='text-[10rem] '>
            {text}
        </UITextShadow>
    )
}

export default RacingGameTextFinish