import { selectIsLastQuestion } from "../redux/modules/room/selectors"
import { saveWinnerHistoryInTrophy } from "../redux/modules/trophy/actions"
import { setSection } from "../redux/modules/ui/reducer"
import { SectionI } from "../redux/modules/ui/types"
import { useAppDispatch, useAppSelector } from "../redux/store"
import { updateCurrentSectionInRoom } from "../services/room"

const useNextToLeaderBoard = () => {

    const dispatch = useAppDispatch()
    const { room } = useAppSelector(state => state.room)
    const isLastQuestion = useAppSelector(selectIsLastQuestion)

    const handleGoToLeaderBoard = async () => {

        try {

            const section:SectionI = !isLastQuestion ? 'leader-board' : 'finish-game'

            await updateCurrentSectionInRoom({
                roomId: room.id,
                currentSection: section,
            })

            if(section==='finish-game'){
                await dispatch(saveWinnerHistoryInTrophy())
            }

            dispatch(setSection(section))

        } catch (e) {

            alert("Error")
            console.error(e)

        }

    }

    return {
        handleGoToLeaderBoard,
    }

}

export default useNextToLeaderBoard