import { useEffect } from "react"
import useCountdown from "../../hooks/useCountdown"
import { setQuestionStatus, setSection } from "../../redux/modules/ui/reducer"
import { useAppDispatch, useAppSelector } from "../../redux/store"

const QuestionTimeRemaining = () => {

  const dispatch = useAppDispatch()
  // const { currentQuestionIndex } = useAppSelector(state => state.ui)
  const {room} = useAppSelector(state=>state.room)
  const {game} = useAppSelector(state=>state.game)

  const defaultCount = (game?.durationBetweenAnswers || 0) + (game?.durationBetweenQuestions || 0)

  const onComplete = () => {
    
    dispatch(setQuestionStatus('answered'))
    
    setTimeout(() => {
      dispatch(setSection('racing-game'))
    }, 4000);

  }

  const { count, resetCount } = useCountdown({
    defaultCount,
    onComplete
  })

  useEffect(() => {
    resetCount()
  }, [room.currentQuestion])

  return (
    <p className="text-gray-700 text-4xl font-normal ">
      {count}
    </p>
  )
}

export default QuestionTimeRemaining