/* eslint-disable @typescript-eslint/no-explicit-any */
import { addDoc, collection, doc, updateDoc } from "firebase/firestore"
import { FS_FIELDS } from "../constants/firebase"
import { db } from "../firebase"

interface Props {
    trophyId: string
    data: any
}


export const saveWinnerHistory = async ({
    trophyId,
    data,
}: Props) => {

    const ref = collection(
        db, FS_FIELDS.TROPHIES,
        trophyId, FS_FIELDS.WINNERS_HISTORY
    )
    return await addDoc(ref, data)

}

export const updateTrophy = async ({
    trophyId,
    data,
}: Props) => {

    const ref = doc(
        db, FS_FIELDS.TROPHIES,
        trophyId
    )

    return await updateDoc(ref, data)

}