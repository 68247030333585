import { useAppSelector } from '../redux/store'
import UIInputQR from './UI/InputQR'

const PinCode = () => {

    const { room } = useAppSelector(state => state.room)
    const pinCode = room.pinCode || ''

    return (
        <div className='flex justify-center gap-1 w-48'>
            {Array.from(pinCode).map((char, index) => (
                <UIInputQR key={index} placeholder={char} widthClassName='w-[4.5rem]' heightClassName='w-[4.5rem]' />
            ))}
        </div>
    )
}

export default PinCode