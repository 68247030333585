import { UICardProps } from "./props"

const BACKGROUND_CLASSNAMES = {
    'blue': 'bg-[#1F3182]',
    'transparent': 'bg-transparent',
    'white':'bg-white',
    'black':'bg-[#131625]',
}

const BORDER_CLASSNAMES = {
    'black': 'border-black',
    'gray': 'border-gray-500'
}

const UICard = ({
    children, className = '',
    background = 'transparent',
    borderColor = 'gray',
}: UICardProps) => {

    return (
        <div className={`${className} my-1 py-3 px-5 rounded-lg border-[1px] ${BACKGROUND_CLASSNAMES[background]} ${BORDER_CLASSNAMES[borderColor]} `}>
            {children}
        </div>
    )
}

export default UICard