export const DEFAULT_CAR_ANIMATION_DURATION = 5

export const DEFAULT_RANGE_MESSAGES = [
    {
        message: "AWESOME",
        min: 90,
        max: 100,
    },
    {
        message: "Don’t worry!",
        min: 0,
        max: 90,
    },
]