import { useEffect, useRef, useState } from "react";

interface Props {
    music: string;
    loop?: boolean;
}

const useSound = ({ music, loop = true }: Props) => {
    const audioRef = useRef(new Audio());
    const [isMuted, setIsMuted] = useState(false);

    useEffect(() => {
        const loadAndPlayAudio = async () => {
            try {
                audioRef.current.src = music;
                audioRef.current.loop = loop;
                await audioRef.current.load();
                audioRef.current.play();
            } catch (error) {
                console.log('Error al cargar el audio', error);
            }
        };

        loadAndPlayAudio();

        return () => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        };
    }, [music, loop]);

    const toggleMute = () => {
        audioRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    return { audioRef, toggleMute };
};

export default useSound;
