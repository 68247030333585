import {
    Action,
    AnyAction,
    combineReducers,
    configureStore,
    ThunkAction
  } from '@reduxjs/toolkit';
  import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

  import roomReducer from './modules/room/reducer'
  import uiReducer from './modules/ui/reducer'
  import gameReducer from './modules/game/reducer'
  import trophyReducer from './modules/trophy/reducer'
  
  const combinedReducer = combineReducers({
    room: roomReducer,
    ui:uiReducer,
    game:gameReducer,
    trophy:trophyReducer,
  });
  
  type Store = ReturnType<typeof makeStore>;
  
  export type AppDispatch = Store['dispatch'];
  export type RootState = ReturnType<Store['getState']>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  
  const reducer = (state: ReturnType<typeof combinedReducer>, action: AnyAction) => {
    return combinedReducer(state, action)
  };
  
  export const makeStore = () =>
    configureStore({
      //@ts-ignore
      reducer,
    });
  
  export const useAppDispatch = () => useDispatch<AppDispatch>();
  export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  
  
  export const wrapper = makeStore;
  