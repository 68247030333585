import PlayerImageNoPhoto from './NoPhoto'
import { USER_IMAGE_BASE_CLASSNAME } from './constants'
import { PlayerImageProps } from './props'

const PlayerImage = ({
  user, className = '',
  baseClassName = USER_IMAGE_BASE_CLASSNAME
}: PlayerImageProps) => {

  if (!user?.photo?.url) {
    return <PlayerImageNoPhoto user={user}
    className={className} baseClassName={baseClassName} />
  }

  return (
    <img src={user.photo.url} alt='player image'
      className={`${baseClassName} ${className}`} />
  )
}

export default PlayerImage