import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store"
import UIButton from "../UI/Button"

const LeaderBoardNextQuestion = () => {

    const { room } = useAppSelector((state) => state.room);
    const [currentQuestion, setCurrentQuestion] = useState(room.currentQuestion);

    useEffect(() => {
        setCurrentQuestion(room.currentQuestion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return (
        <UIButton disabled>
            NEXT: QUESTION {currentQuestion + 2}
        </UIButton>
    );
};

export default LeaderBoardNextQuestion