import { FC } from "react"

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    widthClassName?: string
    heightClassName?: string
}

const UIInputQR: FC<Props> = ({
    widthClassName = 'w-10',
    heightClassName = 'h-10',
    placeholder,
    ...rest
}) => {

    return (
        <input type="text" className={`${widthClassName} ${heightClassName} border text-center placeholder-black bg-purple-ex-light rounded-md text-2xl`} disabled
            {...rest} placeholder={placeholder !== "0" ? placeholder : 'Ø'} />
    )
}

export default UIInputQR