import { useAppSelector } from "../../redux/store"
import { getUsersSortedByScore } from "../../utils/users"
import LeaderBoardCard from "./Card"
import LeaderBoardCardWinner from "./CardWinner"
import { LeaderBoarBaseProps } from "./props"

const propsBase: LeaderBoarBaseProps = {
  background: "black",
  borderColor: "black",
  className: "scale-110",
}

interface Props {
  className?: string
  flexCol?: string
  typeCardWinner?: 'basic' | 'detail'
  bgSecondPlace?: string,
  bgThirdPlace?: string
}

const LeaderBoardWinners = ({
  className = '',
  typeCardWinner = 'basic',
  flexCol= '',
  bgSecondPlace = '',
  bgThirdPlace = ''
}: Props) => {

  const { room, usersIndex } = useAppSelector(state => state.room)

  if (!usersIndex) return

  const players = getUsersSortedByScore({
    users: room.allPlayers,
    scores: room.scores,
  })

  const getUserInPlace = (index: number) => {

    const userId = players[index]
    if (!userId) return undefined

    return usersIndex[userId]

  }

  const FirstPlace = () => {

    if (!firstUser) return null

    if (typeCardWinner === 'detail') {
      return <LeaderBoardCardWinner user={firstUser} />
    }

    return <LeaderBoardCard {...propsBase}
      user={firstUser} position={1} className="w-[32.344vw] h-[3.750vw]" text="st" />

  }

  const firstUser = getUserInPlace(0)
  const secondUser = getUserInPlace(1)
  const thirdUser = getUserInPlace(2)

  return (
    <div className={`flex items-center  ${className}`}>
      <FirstPlace />
      <div className={`flex gap-1 ${flexCol}`}>
        {secondUser && <LeaderBoardCard {...propsBase}
          user={secondUser} position={2} className={`w-[32.344vw] h-[3.750vw] ${bgSecondPlace}`} text="nd"   />}
        {thirdUser && <LeaderBoardCard {...propsBase}
          user={thirdUser} position={3} className={`w-[32.344vw] h-[3.750vw] ${bgThirdPlace}`} text="rd" />}
      </div>
    </div>
  )
}

export default LeaderBoardWinners


