import PinCode from '../PinCode'
import QrCode from '../QrCode'

const EnterCode = () => {
  return (

    <div className='bg-white w-96 p-5 rounded-2xl '>
      <div className='flex justify-center '>
        <h2 className='text-black uppercase leading-10  text-[2.222vw] tracking-tight'>
          Enter the <span className='text-blue-light '>PIN CODE</span> <br />or  <span className='text-blue-light'>sCan the QR Code</span> to join the game.
        </h2>
      </div>

      <div className=' border-purple-500'>
        <p className=' text-gray-light text-center h-10 mt-5 '>PIN CODE</p>

        <div className='flex justify-center'>
          <PinCode />
        </div>

        <div className=' pt-5'>
          <QrCode />
        </div>
      </div>
    </div>
  )
}

export default EnterCode