import { ReactNode } from 'react';
import bgStar from '../../assets/svg/starts.svg'

interface Props {
    children?: ReactNode;
    childrenPadding?: string
    showBgStar?: boolean
    bgLeaderBoard?: string
    blur?: boolean
}

const LeaderBoardContainer = ({
    children,
    childrenPadding = 'px-20',
    showBgStar = true,
    bgLeaderBoard,
}: Props) => {
    return (
        <div className="w-screen h-screen overflow-hidden flex flex-col justify-center items-center relative ">
            {bgLeaderBoard && <img src={bgLeaderBoard} alt="" className="absolute inset-0 z-0 object-cover w-screen h-screen " /> }
            <img src={bgStar} alt="" className={`z-10 absolute ${!showBgStar && 'hidden'}`} />
            <div className={`max-w-full ${childrenPadding} lg:px-0 mx-auto z-10 `}>
                {children}
            </div>
        </div>
    );
}

export default LeaderBoardContainer;
