import btnNext from './../../assets/images/hardcore/next-button.png'

interface Props {
  onClick?: () => void,
}

const ButtonNext = ({ onClick }: Props) => {

  return (
    <img src={btnNext} alt=""
      onClick={onClick} className='cursor-pointer' />
  )
}

export default ButtonNext